











































import Vue from 'vue'
import { mapActions, mapGetters, mapState } from 'vuex'
import { faCaretDown, faFilter } from '@fortawesome/free-solid-svg-icons'
import SortOptionsModal from '../results/header/SortOptionsModal.vue'
import MobileTopBarShareButton from './MobileTopBarShareButton.vue'
import { CLASSIFIED_SEARCH_NS } from '~/store/modules/shared/classifieds/search/state'
import SaveSearchButton from '~/components/car/classifieds/search/results/header/SaveSearchButton.vue'
import CFacetButton from '~/components/shared/configurable/button/CFacetButton.vue'
import { APP_NS } from '~/store/modules/shared/app/state'

export default Vue.extend({
  components: {
    SaveSearchButton,
    MobileTopBarShareButton,
    SortOptionsModal,
    CFacetButton
  },
  props: {
    shareUrl: {
      type: String,
      required: false,
      default: null
    }
  },

  data() {
    return {
      icons: {
        sort: faCaretDown,
        filter: faFilter
      },
      fixedTopBar: false,
      hiddenTopBar: true,
      enableTransition: false,
      scrollOffset: 50,
      offsetTop: null,
      elemHeight: null,
      lastScrollTop: 0
    }
  },
  computed: {
    ...mapState(CLASSIFIED_SEARCH_NS, {
      changeSearchUrl: state => state.changeSearchUrl,
      title: state => state.title || '',
      showSellers: state => state.showSellers
    }),
    ...mapGetters({
      facetCount: 'classifieds/search/getFacetsCount'
    }),
    ...mapGetters(APP_NS, {
      isPlot: 'isPlot'
    }),
    showShareButton() {
      return this.$route.name !== '__classifieds_search_my_classifieds'
    }
  },
  watch: {
    lastScrollTop(val, oldVal) {
      this.calculateOffset()

      if (this.elemHeight && this.offsetTop && val > this.offsetTop) {
        if (val > oldVal) {
          // scroll down
          if (val > this.elemHeight) {
            this.hiddenTopBar = true
            this.fixedTopBar = true
          }
        } else {
          // scroll up

          if (val > this.elemHeight) {
            this.enableTransition = true
          }

          this.hiddenTopBar = false
        }
      } else {
        this.fixedTopBar = false
        this.enableTransition = false
        this.hiddenTopBar = true
      }
    }
  },
  mounted() {
    const scrollListener = () => {
      this.lastScrollTop =
        window.pageYOffset || document.documentElement.scrollTop
    }
    window.addEventListener('scroll', scrollListener, { passive: true })
    this.$once('hook:beforeDestroy', () => {
      window.removeEventListener('scroll', scrollListener)
    })
  },
  methods: {
    ...mapActions(CLASSIFIED_SEARCH_NS, {
      toggleMobileFacets: 'toggleMobileFacets'
    }),
    getOffsetTop() {
      const elem = this.$refs.topbar
      let elemTop = 0
      let offsetElem = elem
      if (offsetElem.offsetParent) {
        do {
          elemTop += offsetElem.offsetTop
          offsetElem = offsetElem.offsetParent
        } while (offsetElem)
      }

      if (elemTop < 0) {
        elemTop = 0
      }

      return elemTop
    },
    showModal() {
      this.$refs.sortModal.showModal()
    },
    calculateOffset() {
      this.offsetTop = this.getOffsetTop()
      this.elemHeight = this.offsetTop + this.$refs.topbar.offsetHeight
    }
  }
})
